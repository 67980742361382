//@flow

import {
    ArrowRightUp,
    ArrowUp,
    Datatable,
    DropdownList,
    Icon,
    InlineEdit,
    Modal,
    Plus,
    useNotifications,
} from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import classnames from "classnames";
import React, { type Node, useEffect, useState } from "react";
import { Button, Col, Collapse, Input } from "reactstrap";

import { useValidation } from "../../hooks";
import style from "./style.module.scss";
import Header from "./subComponents/Header";
import ViewSetTableBody from "./subComponents/ViewSetTableBody";
import type { ViewSetVersion } from "./types/view-set-version.d";

export type Settings = {
    columns: {
        key: string,
        label: string,
        editable?: boolean,
        thumbnail?: boolean,
        formatter?: (value: any) => string,
    }[],
    rowId: string,
};

export type Actions = {
    icon: Node,
    label: string,
    action: string,
    enable?: {
        key: string,
        condition: () => boolean,
    },
}[];

type Props = {
    settings: Settings,
    actions: Actions,
    data: {
        [string]: any,
    }[],
    title: string,
    order: number,
    onDelete: () => void,
    open: boolean,
    enableEdit?: boolean,
    onCancel: () => void,
    onAddItem: () => void,
    onUpdateSetValue: (number, string, string) => void,
    onUpdateTitle: string => void,
    onAction: (action: string, index: number) => void,
    onUpdateViewVersion: ({
        [string]: any,
    }) => void,
    loading: boolean,
    onSave: () => Promise<boolean>,
    nameField?: string,
    onToggle: () => void,
    deleteConfirmationLabel: string,
    readOnly?: boolean,
    resultsMapper?: (
        {
            [string]: any,
        }[],
    ) => {
        [string]: any,
    }[],
};

const ViewSet = ({
    settings,
    data,
    order,
    title,
    onDelete: handleDelete,
    open,
    enableEdit,
    onCancel: handleCancel,
    onSave: handleSave,
    onAddItem: handleAddItem,
    actions,
    onUpdateViewVersion: handleUpdateViewVersion,
    onUpdateSetValue: handleUpdateSetValue,
    onUpdateTitle: handleUpdateTitle,
    onAction: handleAction,
    loading,
    nameField,
    onToggle: handleToggle,
    deleteConfirmationLabel,
    resultsMapper,
    readOnly,
}: Props): Node => {
    const [editMode, setEditmode] = useState(!!enableEdit);
    const [showModal, setShowModal] = useState();
    useEffect(() => setEditmode(!!enableEdit), [enableEdit]);
    const { addNotification } = useNotifications();

    const cancel = () => {
        unregisterFields();
        setEditmode(false);
        handleCancel();
    };

    const [, fetchAllLatestVersions] = useAxios(
        {
            url: "ams-api/frame/frame-latest-versions",
            method: "POST",
        },
        { manual: true, useCache: false },
    );

    const [, fetchAllLatestPublishedVersions] = useAxios(
        {
            url: "ams-api/frame/frame-latest-published-versions",
            method: "POST",
        },
        { manual: true, useCache: false },
    );

    const handleHeaderAction = (action: string) => {
        const handleUpdateViewVersionsResponse = response => {
            if (response?.status === 200) {
                const latestViewVersions: { [string]: ViewSetVersion } =
                    response?.data?.results?.reduce((acc, tuple) => {
                        const frameVersionId = Object.keys(tuple)?.[0];

                        if (frameVersionId) {
                            const newViewVersion: ViewSetVersion =
                                tuple[frameVersionId];

                            let found = data.find(
                                oldViewVersion =>
                                    oldViewVersion.frameVersionId ===
                                    frameVersionId,
                            );

                            if (
                                found &&
                                (found.sceneVersion !==
                                    newViewVersion.sceneVersion ||
                                    newViewVersion.removed)
                            ) {
                                acc[frameVersionId] = newViewVersion;
                            }
                        }

                        return acc;
                    }, {});

                if (Object.keys(latestViewVersions).length > 0) {
                    if (!open) {
                        handleToggle();
                    }
                    setEditmode(true);
                    handleUpdateViewVersion(latestViewVersions);

                    const updateInfo = Object.keys(latestViewVersions).reduce(
                        (acc, key) => {
                            if (latestViewVersions[key].removed) {
                                acc.removed++;
                            } else {
                                acc.updated++;
                            }
                            return acc;
                        },
                        {
                            removed: 0,
                            updated: 0,
                        },
                    );

                    if (updateInfo.removed > 0) {
                        addNotification({
                            type: "warning",
                            body:
                                updateInfo.removed === 1
                                    ? "1 view has been removed from the scene and it will be deleted when pressing save."
                                    : `${updateInfo.removed} views have been removed from the scene and they will be deleted when pressing save.`,
                            autoHide: true,
                            timeout: 5000,
                        });
                    }

                    if (updateInfo.updated > 0) {
                        addNotification({
                            type: "success",
                            body:
                                updateInfo.updated === 1
                                    ? "1 item has a newer version available."
                                    : `${updateInfo.updated} items have newer versions available for update.`,
                            autoHide: true,
                            timeout: 5000,
                        });
                    }
                } else {
                    addNotification({
                        type: "success",
                        body: "Already up-to-date: No updates are available for these items.",
                        autoHide: true,
                        timeout: 5000,
                    });
                }
            }
        };

        if (action === "ALL_TO_LATEST") {
            fetchAllLatestVersions({
                data: data.map(view => view.frameVersionId),
            }).then(handleUpdateViewVersionsResponse);
        } else {
            fetchAllLatestPublishedVersions({
                data: data.map(view => view.frameVersionId),
            }).then(handleUpdateViewVersionsResponse);
        }
    };

    const handleActionSelect = (action, index, element) => {
        if (action === "DELETE") {
            unregisterFields();
            setEditmode(true);
            handleAction(action, index);
        } else if (action === "RELINK") {
            setEditmode(true);
            handleAction(action, index);
        } else {
            const handleUpdateViewVersionResponse = response => {
                if (response?.status === 200) {
                    const tuple = response?.data?.results?.[0];
                    if (tuple) {
                        const frameVersionId = Object.keys(tuple)?.[0];

                        if (frameVersionId) {
                            const newViewVersion = tuple[frameVersionId];

                            if (
                                element.sceneVersion?.props?.version ===
                                    newViewVersion.sceneVersion &&
                                !newViewVersion.removed
                            ) {
                                addNotification({
                                    type: "success",
                                    body: "Already up-to-date: No updates are available for this item.",
                                    autoHide: true,
                                    timeout: 5000,
                                });
                            } else {
                                setEditmode(true);
                                handleUpdateViewVersion({
                                    [frameVersionId]: newViewVersion,
                                });

                                if (newViewVersion.removed) {
                                    addNotification({
                                        type: "warning",
                                        body: "This view has been removed from the scene and it will be deleted when pressing save.",
                                        autoHide: true,
                                        timeout: 5000,
                                    });
                                } else {
                                    addNotification({
                                        type: "success",
                                        body: "This item has a newer version available for update.",
                                        autoHide: true,
                                        timeout: 5000,
                                    });
                                }
                            }
                        } else {
                            addNotification({
                                type: "success",
                                body: "Already up-to-date: No updates are available for this item.",
                                autoHide: true,
                                timeout: 5000,
                            });
                        }
                    } else {
                        addNotification({
                            type: "success",
                            body: "Already up-to-date: No updates are available for this item.",
                            autoHide: true,
                            timeout: 5000,
                        });
                    }
                }
            };

            if (action === "UPDATE_TO_LATEST") {
                fetchAllLatestVersions({
                    data: [element?.frameVersionId],
                }).then(handleUpdateViewVersionResponse);
            } else if (action === "UPDATE_TO_LATEST_PUBLISHED") {
                fetchAllLatestPublishedVersions({
                    data: [element?.frameVersionId],
                }).then(handleUpdateViewVersionResponse);
            }
        }
    };

    const [{ formik }, submit, unregisterFields] = useValidation({
        title,
        data,
        nameField,
        onSave: async () => {
            const success = await handleSave();
            if (success) {
                setEditmode(false);
            }
        },
    });

    const confirmDelete = (): void => {
        setShowModal(false);
        handleDelete();
    };

    return (
        <div
            className={classnames(
                "px-0",
                style.viewSetBody,
                editMode ? style.expanded : style.collapsed,
            )}
        >
            <InlineEdit
                isSubmitting={loading}
                active={editMode}
                onCancel={cancel}
                onSave={submit}
                isOpen={open}
                saveAllowed={formik.isValid}
            >
                <Header
                    order={order}
                    onToggle={handleToggle}
                    editMode={editMode}
                    isOpen={open}
                    title={title}
                    onDelete={() => setShowModal(true)}
                    onEnableEditMode={() => setEditmode(true)}
                    onUpdateTitle={handleUpdateTitle}
                    loading={loading}
                    validationError={formik.errors["title"]}
                    readOnly={readOnly}
                    leftAction={
                        <DropdownList
                            direction={"down"}
                            listItems={[
                                {
                                    label: "Update all to latest",
                                    value: "ALL_TO_LATEST",
                                    icon: ArrowUp,
                                },
                                {
                                    label: "Update all to latest published",
                                    value: "ALL_TO_LATEST_PUBLISHED",
                                    icon: ArrowRightUp,
                                },
                            ]}
                            onSelect={value =>
                                !loading && handleHeaderAction(value)
                            }
                        >
                            <Button color={"white"}>
                                <ArrowRightUp />
                            </Button>
                        </DropdownList>
                    }
                >
                    <Col>
                        {editMode ? (
                            <Input
                                onChange={event =>
                                    handleUpdateTitle(event.target.value)
                                }
                                value={title}
                            />
                        ) : (
                            title
                        )}
                        {formik.errors["title"] && (
                            <small className="text-danger">
                                {formik.errors["title"]}
                            </small>
                        )}
                    </Col>
                </Header>
                <Collapse isOpen={open}>
                    <Datatable
                        settings={settings}
                        disableRowSelect
                        hideNavigation
                        disableSort
                    >
                        <ViewSetTableBody
                            setIndex={order}
                            settings={settings}
                            data={resultsMapper ? resultsMapper(data) : data}
                            editMode={editMode}
                            onUpdateValue={handleUpdateSetValue}
                            actions={actions}
                            onActionSelect={handleActionSelect}
                            errors={formik.errors}
                            readOnly={readOnly}
                        />
                    </Datatable>
                    {editMode && (
                        <Button
                            className={`w-100 text-secondary py-2`}
                            color="light-grey"
                            onClick={handleAddItem}
                        >
                            <Icon
                                icon={Plus}
                                className="mr-2"
                                variant="secondary"
                            />
                            Add items
                        </Button>
                    )}
                </Collapse>
            </InlineEdit>
            <Modal
                confirmation
                open={showModal}
                title={"confirmation"}
                onCancel={() => setShowModal(false)}
                actions={[
                    {
                        type: "primary",
                        label: "Delete",
                        action: confirmDelete,
                    },
                    {
                        type: "secondary",
                        label: "Cancel",
                        action: () => setShowModal(false),
                    },
                ]}
            >
                {deleteConfirmationLabel}
            </Modal>
        </div>
    );
};

export default ViewSet;
