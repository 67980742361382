// @flow

import {
    Button,
    ChevronDown,
    ChevronUp,
    Heading,
    Icon,
    LoadIndicator,
    Pencil,
    Plus,
    useNotifications,
} from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import React, {
    type Node,
    Fragment,
    useContext,
    useEffect,
    useState,
} from "react";
import { Button as Btn } from "reactstrap";

import {
    ColumnCustomizer,
    ErrorHandling,
    FramesSelection,
    VersionCell,
    ViewSet,
} from "../../components";
import { useCollapse, useViewSets } from "../../hooks";
import { FormContext } from "../../providers/form-provider";
import sharedStyles from "../../styles/shared.module.scss";
import UpdateProjectHeading from "../UpdateProjectHeading";
import frameTableSettings from "./settings/frame-table-settings";
import viewTableActions from "./settings/view-table-actions";
import viewTableSettings from "./settings/view-table-settings";
import { mapResults } from "./utils";

type Props = {
    projectId: string,
    projectName: string,
    isAutomaticRendering: boolean,
    toggleAutomaticRendering: boolean => void,
    readOnly: boolean,
};

const ProjectViews = ({
    projectId,
    projectName,
    isAutomaticRendering = false,
    toggleAutomaticRendering: handleToggleAutomaticRendering,
    readOnly,
}: Props): Node => {
    const [showFrameSelection, setShowFrameSelection] = useState(false);
    const [showColumnCustomizer, setShowColumnCustomizer] = useState(false);
    const { addNotification } = useNotifications();

    const {
        addCollapsible,
        collapseAll,
        expandAll,
        collapsed,
        removeCollapsible,
        toggleCollapse,
    } = useCollapse();
    const [
        {
            sets: viewSets,
            updateSetData,
            customColumns,
            loading,
            indexNewSet,
            indexSetToExtend,
            itemToRelink,
            deleteSetLoading,
            createSetLoading,
            updateSetLoading,
            metadata,
        },
        cancelEdit,
        setIndexSetToExtend,
        updateItemValue,
        updateSetName,
        ,
        relinkItem,
        addItemToSet,
        addSet,
        deleteSet,
        createSet,
        updateSet,
        handleAction,
        fetchData,
        cancelRelink,
        updateViewsVersions,
    ] = useViewSets({
        apiBase: `/ams-api/project/${projectId}/viewSets`,
        setsKey: "viewSets",
        setKey: "views",
        setNameKey: "viewSetName",
        setId: "viewSetId",
    });

    useEffect(() => {
        if (updateSetData && updateSetData.allPairingsValid === false) {
            addNotification({
                type: "danger",
                body: "Some pairings using the viewset aren't valid. Please check the pairings section.",
                autoHide: true,
                timeout: 5000,
            });
        }
    }, [updateSetData]);

    const [{ loading: framesLoading, data: framesData }, loadFrames] = useAxios(
        {
            url: `/ams-api/frame/version/retrieveVersionDetails`,
            method: "POST",
        },
        {
            useCache: false,
            manual: true,
        },
    );
    const [{ loading: updatMetaFieldsDataLoading }, _updateViewMetadataFields] =
        useAxios(
            {
                url: `/ams-api/project/${projectId}/viewMetadataField`,
                method: "POST",
            },
            {
                useCache: false,
                manual: true,
            },
        );

    useEffect(() => {
        viewSets.forEach(set =>
            addCollapsible(
                set.viewSetId,
                collapsed(set.viewSetId) !== undefined
                    ? collapsed(set.viewSetId)
                    : set.viewSetId !== undefined,
            ),
        );
    }, [viewSets]);

    useEffect(() => {
        if (framesData?.results?.length) {
            const newFrame = framesData.results[0];
            if (itemToRelink) {
                relinkItem(newFrame);
            } else if (indexSetToExtend || indexSetToExtend === 0) {
                framesData.results.forEach(frame => {
                    addItemToSet({
                        ...frame,
                        metadata,
                        viewName: "",
                    });
                });
            } else {
                addSet(
                    framesData?.results.map(frame => ({
                        ...frame,
                        metadata,
                        viewName: "",
                    })),
                );
            }
        }
    }, [framesData]);

    const getFrames = (selectedFrames: { [string]: any }[]) => {
        setShowFrameSelection(false);
        loadFrames({
            data: { frameVersions: selectedFrames },
        });
    };

    const deleteViewSet = (viewSetId: string, setIndex: number): void => {
        if (indexNewSet || indexNewSet === 0) {
            cancelEdit(setIndex);
        } else {
            deleteSet(
                `/ams-api/project/${projectId}/viewSet/${viewSetId}`,
                setIndex,
            );
            removeCollapsible(viewSetId);
        }
    };

    const { setDirty } = useContext(FormContext);

    const saveChanges = (set: { [string]: any }): Promise<boolean> => {
        setDirty(false);
        if (indexNewSet || indexNewSet === 0) {
            return createSet(`/ams-api/project/${projectId}/viewSet`, set);
        } else {
            return updateSet(
                `/ams-api/project/${projectId}/viewSet/${set.viewSetId}`,
                {
                    ...set,
                    views: set.views.filter(element => !element.removed),
                },
            );
        }
    };

    const updateViewMetadataFields = async (viewMetadataFields: string[]) => {
        await _updateViewMetadataFields({
            data: { viewMetadataFields },
        });
        fetchData();
        setShowColumnCustomizer(false);
    };

    const actions = (
        <Fragment>
            <Btn color="none" onClick={() => collapseAll()}>
                <Icon className="mr-2 pb-1" icon={ChevronUp} /> Collapse all
            </Btn>
            <Btn color="none" onClick={() => expandAll()}>
                <Icon className="mr-2 pb-1" icon={ChevronDown} /> Expand all
            </Btn>
            {!readOnly && (
                <Btn
                    color="none"
                    disabled={isAutomaticRendering}
                    onClick={() => setShowColumnCustomizer(true)}
                >
                    <Icon
                        className="mr-2 pb-1"
                        variant="secondary"
                        icon={Pencil}
                    />{" "}
                    Edit column
                </Btn>
            )}
        </Fragment>
    );

    return (
        <Fragment>
            <ErrorHandling
                exclude={[`/ams-api/project/${projectId}/viewMetadataField`]}
            />
            <UpdateProjectHeading
                toggleAutomaticRendering={handleToggleAutomaticRendering}
                isAutomaticRendering={isAutomaticRendering}
                projectName={projectName}
                readOnly={readOnly}
                rightContent={
                    <Button
                        color="light-blue"
                        onClick={() => setShowFrameSelection(true)}
                        disabled={isAutomaticRendering}
                    >
                        ADD VIEW SET
                    </Button>
                }
            />
            <Heading
                title={
                    viewSets?.length || !isAutomaticRendering ? "View sets" : ""
                }
                level={5}
                rightContent={!!viewSets?.length && actions}
            />
            {loading ? (
                <LoadIndicator cols={1} rows={1} />
            ) : (
                !viewSets?.length &&
                !framesLoading &&
                !isAutomaticRendering && (
                    <div className={sharedStyles.addContainer}>
                        <Button
                            color="link"
                            onClick={() => setShowFrameSelection(true)}
                        >
                            <Icon
                                className="mb-1 mr-2"
                                variant="secondary"
                                icon={Plus}
                            />
                            <span className="text-secondary">Add view set</span>
                        </Button>
                    </div>
                )
            )}
            {!!viewSets.length &&
                viewSets.map((set, setIdx) => (
                    <ViewSet
                        readOnly={isAutomaticRendering || readOnly}
                        order={setIdx + 1}
                        key={setIdx}
                        settings={{
                            ...viewTableSettings,
                            columns: [
                                ...viewTableSettings.columns,
                                ...customColumns,
                            ],
                        }}
                        deleteConfirmationLabel={
                            "Are you sure you want to delete this view set?"
                        }
                        data={set.views}
                        title={set.viewSetName}
                        onDelete={() => {
                            deleteViewSet(set.viewSetId, setIdx);
                            setDirty(true);
                        }}
                        open={collapsed(set.viewSetId) === false}
                        enableEdit={indexNewSet === setIdx}
                        onCancel={() => cancelEdit(setIdx)}
                        onAddItem={() => {
                            setIndexSetToExtend(setIdx);
                            setShowFrameSelection(true);
                            setDirty(true);
                        }}
                        actions={viewTableActions}
                        onUpdateViewVersion={versions =>
                            updateViewsVersions(setIdx, versions)
                        }
                        onUpdateSetValue={(viewIndex, key, value) => {
                            updateItemValue(viewIndex, key, value, setIdx);
                            setDirty(true);
                        }}
                        onUpdateTitle={value => {
                            updateSetName(value, setIdx);
                            setDirty(true);
                        }}
                        onAction={(action, viewIndex) =>
                            handleAction(action, viewIndex, setIdx, () =>
                                setShowFrameSelection(true),
                            )
                        }
                        loading={
                            deleteSetLoading ||
                            createSetLoading ||
                            updateSetLoading
                        }
                        onSave={() => saveChanges(set)}
                        nameField="viewName"
                        onToggle={() => toggleCollapse(set.viewSetId)}
                        resultsMapper={rows =>
                            rows.map((row, rowIdx) => ({
                                ...row,
                                sceneVersion: (
                                    <VersionCell
                                        cellId={`tt_${setIdx}_${rowIdx}_status`}
                                        version={row.sceneVersion}
                                        versionIndicator={row.versionIndicator}
                                    />
                                ),
                            }))
                        }
                    />
                ))}
            {!!framesLoading && (!!indexNewSet || indexNewSet === 0) && (
                <LoadIndicator cols={4} rows={4} />
            )}
            {showFrameSelection && (
                <FramesSelection
                    resultsMapper={mapResults}
                    tableSettings={frameTableSettings}
                    apiBase={"dap-search-service/frame"}
                    onConfirm={getFrames}
                    params={{
                        archived: false,
                    }}
                    onCancel={() => {
                        setShowFrameSelection(false);

                        if (itemToRelink) {
                            cancelRelink();
                        } else if (indexSetToExtend === undefined) {
                            addSet();
                        }

                        setIndexSetToExtend();
                    }}
                    title="Select frames for view set"
                    multiSelect={!itemToRelink}
                    selected={viewSets[
                        itemToRelink ? itemToRelink.indexSet : indexSetToExtend
                    ]?.views?.map(view => view[frameTableSettings.rowId])}
                />
            )}
            {showColumnCustomizer && (
                <ColumnCustomizer
                    errorKey={`/ams-api/project/${projectId}/viewMetadataField`}
                    onClose={() => setShowColumnCustomizer(false)}
                    columns={[...viewTableSettings.columns, ...customColumns]}
                    loading={updatMetaFieldsDataLoading}
                    onSubmit={updateViewMetadataFields}
                />
            )}
        </Fragment>
    );
};

export default ProjectViews;
