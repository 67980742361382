// @flow
import useAxios from "axios-hooks";
import React, { type Node, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { Permissions } from "../../api/permissions";
import { ErrorHandling } from "../../components";
import { useCreate, usePermissions } from "../../hooks";
import OverviewTable from "../../templates/OverviewTable";
import CloneProject from "./components/CloneProject";
import CreateProject from "./components/CreateProject";
import actions from "./settings/actions";
import tableSettings from "./settings/table";
import { mapResults } from "./utils";

const CREATE_PROJECT_URL = "/ams-api/project";

const createFormKey = "create-project-form";

const ProjectsOverview = (): Node => {
    const [showProjectCosts] = usePermissions([Permissions.PROJECT_ADMIN]);
    const [userOptions, setUserOptions] = useState<
        { value: string, label: string }[],
    >([]);
    const [companiesOptions, setCompaniesOptions] = useState<
        { value: string, label: string }[],
    >([]);
    const [exclude, setExclude] = useState([CREATE_PROJECT_URL, createFormKey]);
    const [projectId, setProjectId] = useState();
    const [showCreate, setShowCreate] = useState<boolean>(false);

    const [canManage, canRead, canArchive] = usePermissions([
        Permissions.PROJECT_MANAGE,
        Permissions.PROJECT_READ,
        Permissions.PROJECT_ARCHIVE,
    ]);

    const overviewSettings = {
        title: "Projects Overview",
        apiBase: "dap-search-service/project",
        actionApiBase: "ams-api/project",
        searchSuffix: showProjectCosts && "search-with-costs",
        canManage,
        createButtonLabel: "create project",
        tableSettings: tableSettings(showProjectCosts),
        mapResults,
        actions: actions(canManage, canArchive),
        identifiers: ["projectId"],
        updateLinkBase: "projects",
        refresh: true,
    };

    const handleAction = (
        action: string,
        selected: { [string]: any } | { [string]: any }[],
        fetchResults: () => void,
        executeDefaultTableAction: (
            action: string,
            data: { [string]: any } | { [string]: any }[],
        ) => void,
    ): void => {
        if (["archive", "unarchive"].includes(action)) {
            executeDefaultTableAction(action, selected);
        } else if ("clone" === action) {
            if (!Array.isArray(selected)) {
                setProjectId(selected?.projectId);
            }
        }
    };

    const [{ data: metaData, loading: metaDataLoading }, fetchMetaData] =
        useAxios(
            {},
            {
                useCache: false,
                manual: true,
            },
        );

    useEffect(() => {
        if (projectId) {
            fetchMetaData({
                url: `/ams-api/project/${projectId}/metadata`,
            });

            setExclude(draft => {
                draft.push(`/ams-api/project/${projectId}/clone`);
                return draft;
            });
        }
    }, [projectId]);

    const [{ data: usersData }] = useAxios(`/auth/users`, {
        useCache: false,
    });

    const [
        { data: companiesData, loading: companiesLoading },
        fetchCompaniesData,
    ] = useAxios(`/ams-api/company`, {
        useCache: false,
        manual: true,
    });

    useEffect(() => {
        if (usersData?.userNames?.length) {
            setUserOptions(
                usersData.userNames.map(userName => ({
                    value: userName,
                    label: userName,
                })),
            );
        }
    }, [usersData]);

    useEffect(() => {
        if (companiesData?.companies?.length) {
            setCompaniesOptions(
                companiesData?.companies.map(({ companyId, companyName }) => ({
                    value: companyId,
                    label: companyName,
                })),
            );
        }
    }, [companiesData, companiesLoading]);

    useEffect(() => {
        if (showCreate || metaData) {
            fetchCompaniesData();
        }
    }, [showCreate, metaData]);

    const [{ createLoading }, create] = useCreate({
        overviewUrl: "/projects",
    });

    const [{ loading: cloneLoading }, clone] = useAxios(
        {
            method: "POST",
        },
        { manual: true, useCache: false },
    );

    return (
        <>
            <Helmet>
                <title>Twinbru Engine - Projects</title>
            </Helmet>
            <CloneProject
                toggle={() => setProjectId()}
                show={
                    projectId !== undefined &&
                    metaData &&
                    metaData.projectId === projectId
                }
                metaData={metaData}
                create={clone}
                createLoading={cloneLoading}
                usersOptions={userOptions}
                companiesOptions={companiesOptions}
            />
            <CreateProject
                show={showCreate}
                toggle={() => setShowCreate(false)}
                create={create}
                createLoading={createLoading}
                usersOptions={userOptions}
                companiesOptions={companiesOptions}
            />
            <ErrorHandling exclude={exclude} />
            {canRead && (
                <OverviewTable
                    {...overviewSettings}
                    customCreateAction={() => setShowCreate(true)}
                    onCustomDataTableAction={handleAction}
                    loading={createLoading || cloneLoading || metaDataLoading}
                />
            )}
        </>
    );
};

export default ProjectsOverview;
