// @flow
import {
    Button,
    Card,
    Datatable,
    DetailsForm,
    FileUpload,
    Heading,
    LoadIndicator,
    Spinner,
    useNotifications,
    useSettings,
} from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import { useFormik } from "formik";
import { navigate } from "gatsby";
import React, {
    type Node,
    Fragment,
    useContext,
    useEffect,
    useState,
} from "react";
import { Container, Input } from "reactstrap";
import { useImmer } from "use-immer";

import { axiosInstance } from "../../api";
import { ErrorHandling, FrameDetailsBody } from "../../components";
import ComponentDescriptionsBody from "../../components/ComponentDescriptionsBody";
import type { Component } from "../../components/Pairing/types/pairing.d";
import { useErrorHandlingWithForm, useUpload } from "../../hooks";
import type { ContentLocation } from "../../hooks/use-upload";
import { FormContext } from "../../providers/form-provider";
import componentTableSettings from "./settings/component-table-settings";
import frameTableSettings from "./settings/frame-table-settings";
import style from "./style.module.scss";

export type FrameType = {
    sequence: number,
    cameraName: string,
    resolutionNumerator: number,
    resolutionDenominator: number,
    compositingTemplateFilePath: string,
    label: string,
};

type Props = {
    title: string,
    type: "Create" | "Update",
    submitLoading?: boolean,
    dataLoading?: boolean,
    readOnly?: boolean,
    validationSettings: {
        initialValues: { [string]: string },
        validationSchema: any,
    },
    formSettings: {
        label: string,
        key: string,
        placeholder: string,
        inputType: string,
        required: boolean,
    }[],
    onSubmit: ({ [string]: string }, { [string]: string }, [], string) => void,
    data?: {
        companyId: string,
        floorType: string,
        sceneName: string,
        wallColour: string,
        assetType: string,
        lightSetting: string,
        roomType: string,
        contentLocation?: ContentLocation,
        frames: any[],
        componentDefinitions: any[],
        availableCompositingTemplateFilePaths: any[],
    } | void,
};

const EditScene = ({
    title,
    type,
    submitLoading,
    dataLoading,
    validationSettings,
    onSubmit: handleSubmit,
    data,
    formSettings,
    readOnly = false,
}: Props): Node => {
    const { addNotification } = useNotifications();
    const [cachedFrames, setCachedFrames] = useImmer<FrameType[]>([]);
    const [cachedComponents, setCachedComponents] = useImmer([]);
    const [frames, setFrames] = useImmer<FrameType[]>([]);
    const [components, setComponents] = useImmer([]);
    const [templateFilePaths, setTemplateFilePaths] = useImmer([]);
    const [frameDescriptions, setFrameDescriptions] = useImmer([]);
    const [frameDetailsLoading, setFrameDetailsLoading] = useState(false);
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);

    const [{ loading: scenesLoading, data: scenes }] = useAxios(
        `/dap-search-service/scene/list`,
        {
            useCache: false,
        },
    );

    const [{ data: frameOptions }] = useAxios(`/ams-api/frame/list`, {
        useCache: false,
    });

    const [{ data: companiesData, loading: companiesDataLoading }] = useAxios(
        `/ams-api/company`,
        {
            useCache: false,
        },
    );

    const [{ data: componentDescriptions }] = useAxios(
        {
            url: `/ams-api/component/list`,
        },
        {
            useCache: false,
        },
    );

    const [sceneUploadStatus, setSceneUploadStatus] = useState();
    const [updateSceneUploadStatusLoading, setUpdateSceneUploadStatusLoading] =
        useState();

    const getUploadStatus = data => {
        setUpdateSceneUploadStatusLoading(true);
        axiosInstance
            .post("ams-api/scene/upload/status", JSON.stringify(data))
            .then(resp => {
                if (resp.status === 200) {
                    setUpdateSceneUploadStatusLoading(false);
                    return resp.data;
                }

                if (resp.status === 409) {
                    setFrameDetailsLoading(false);
                    formik.setFieldError(
                        "uploadStatus",
                        "The file isn't valid",
                    );
                } else {
                    formik.setFieldError("uploadStatus");
                }
                setUpdateSceneUploadStatusLoading(false);
            })
            .then(data => setSceneUploadStatus(data));
    };

    const [
        { settings: sceneSettings },
        updateSceneSelectOptions,
        updateLoading,
    ] = useSettings(formSettings);

    const [
        {
            progress: uploadProgress,
            done: uploadFinished,
            loading: uploadLoading,
            uploadData,
            errorOnUpload,
            fileName,
        },
        uploadFile,
        resetUpload,
    ] = useUpload("/ams-api/scene", data?.contentLocation);

    const formik = useFormik({
        ...validationSettings,
        onSubmit: () => {
            const frameDefinitions = {};
            frames.forEach(
                (frame, index) =>
                    (frameDefinitions[(index + 1).toString()] = frame),
            );
            handleSubmit(
                formik.values,
                frameDefinitions,
                components,
                uploadData?.Location,
            );
        },
    });

    const { dirty, setDirty } = useContext(FormContext);

    useErrorHandlingWithForm("edit-scene-form", formik.errors);

    useEffect(() => {
        if (data) {
            formik.resetForm({ values: { ...formik.values, ...data } });
            if (data.frames) {
                setFrames(() =>
                    data.frames.map((frame, index) => ({
                        ...frame,
                        sequence: index + 1,
                    })),
                );
                setCachedFrames(() =>
                    data.frames.map((frame, index) => ({
                        ...frame,
                        sequence: index + 1,
                    })),
                );
            }

            if (data.componentDefinitions) {
                setComponents(() => data.componentDefinitions);
                setCachedComponents(() => data.componentDefinitions);
            }

            if (data.availableCompositingTemplateFilePaths) {
                setTemplateFilePaths(
                    () => data.availableCompositingTemplateFilePaths,
                );
            }
        }
    }, [data]);

    useEffect(() => {
        if (scenes?.result.length) {
            scenes.result.forEach(result => {
                updateSceneSelectOptions(
                    result.field,
                    result.results.map(value => ({
                        value,
                        label: value,
                    })),
                );
            });
        }
    }, [scenes]);

    useEffect(() => {
        if (frames?.length) {
            frames.forEach((frame, index) => {
                const frameLabel = `frame-${index}-description`;
                if (
                    !Object.prototype.hasOwnProperty.call(
                        formik.values,
                        frameLabel,
                    )
                ) {
                    formik.registerField(frameLabel, {
                        validate: value =>
                            !value ? "Description field is required" : null,
                    });
                    if (frame.label) {
                        updateFrameDescription(frame.label, index);
                    }
                }
            });

            frames.forEach((frame, index) => {
                const frameTemplateFilePath = `frame-${index}-template-file-path`;
                if (
                    !Object.prototype.hasOwnProperty.call(
                        formik.values,
                        frameTemplateFilePath,
                    )
                ) {
                    formik.registerField(frameTemplateFilePath, {
                        validate: value =>
                            !value
                                ? "Frame template file path field is required"
                                : null,
                    });
                    if (frame.compositingTemplateFilePath) {
                        updateFileTemplatePath(
                            frame.compositingTemplateFilePath,
                            index,
                        );
                    }
                }
            });
        }
    }, [frames]);

    useEffect(() => {
        if (components?.length) {
            components.forEach((component, index) => {
                const componentDescription = `component-${index}-description`;
                if (
                    !Object.prototype.hasOwnProperty.call(
                        formik.values,
                        componentDescription,
                    )
                ) {
                    formik.registerField(componentDescription, {
                        validate: value =>
                            !value ? "Description field is required" : null,
                    });
                    if (component.description) {
                        updateComponentDescription(
                            component.description,
                            index,
                        );
                    }
                }
            });
        }
    }, [components]);

    useEffect(() => {
        if (frameOptions?.result?.length) {
            const descriptions = frameOptions.result.find(
                option => option.field === "description",
            );
            if (descriptions?.results?.length) {
                setFrameDescriptions(() =>
                    descriptions.results.map(result => result),
                );
            }
        }
    }, [frameOptions]);

    useEffect(() => {
        if (sceneUploadStatus?.status) {
            const status = sceneUploadStatus.status;

            formik.setFieldValue(
                "uploadStatus",
                status,
                shouldValidateOnChange,
            );

            if (status === "VALID") {
                sceneUploadStatus.availableCompositingTemplateFilePaths &&
                    setTemplateFilePaths(() =>
                        Object.values(
                            sceneUploadStatus.availableCompositingTemplateFilePaths,
                        ),
                    );

                if (sceneUploadStatus.frameDefinitions) {
                    const newFrameDefinitions: Array<FrameType> =
                        (Object.values(
                            sceneUploadStatus.frameDefinitions,
                        ): any);

                    setFrames(() =>
                        newFrameDefinitions.map((newFrame: FrameType) => {
                            const oldFrame = cachedFrames.find(
                                cachedFrame =>
                                    cachedFrame.cameraName ===
                                    newFrame.cameraName,
                            );

                            if (oldFrame) {
                                return {
                                    ...newFrame,
                                    compositingTemplateFilePath:
                                        oldFrame.compositingTemplateFilePath,
                                    label: oldFrame.label,
                                };
                            } else {
                                return newFrame;
                            }
                        }),
                    );
                }

                if (sceneUploadStatus.componentDefinitions) {
                    const newComponents: Array<Component> = (Object.values(
                        sceneUploadStatus.componentDefinitions,
                    ): any);

                    setComponents(() =>
                        newComponents.map((newComponent: Component) => {
                            const oldComponent = cachedComponents.find(
                                cachedComponent =>
                                    cachedComponent.componentId ===
                                    newComponent.componentId,
                            );

                            if (oldComponent) {
                                return {
                                    ...newComponent,
                                    name: oldComponent.name,
                                    description: oldComponent.description,
                                };
                            } else {
                                return newComponent;
                            }
                        }),
                    );
                }

                if (type === "Update") {
                    addNotification({
                        type: "warning",
                        body: "The frames and components may have changed, please review them.",
                        autoHide: true,
                        timeout: 5000,
                    });
                }

                setFrameDetailsLoading(false);
            } else if (status === "IN_PROGRESS") {
                setTimeout(
                    () =>
                        getUploadStatus({
                            s3Url: uploadData.Location,
                        }),
                    3000,
                );
            } else if (status === "INVALID") {
                setFrameDetailsLoading(false);
            }
            setDirty(true);
        }
    }, [sceneUploadStatus]);

    useEffect(() => {
        formik.setFieldValue(
            "fileUrl",
            uploadData?.Location,
            shouldValidateOnChange,
        );
        if (uploadData?.Location) {
            setDirty(true);
            setFrameDetailsLoading(true);
            getUploadStatus({
                s3Url: uploadData.Location,
            });
        } else {
            if (frames?.length) {
                frames.forEach((frame, index) => {
                    const frameLabel = `frame-${index}-description`;
                    formik.unregisterField(frameLabel);
                });
                if (shouldValidateOnChange) {
                    formik.validateForm();
                }
            }
            setFrames(() => []);

            if (components?.length) {
                components.forEach((component, index) => {
                    const componentDescription = `component-${index}-description`;
                    formik.unregisterField(componentDescription);
                });
            }
            setComponents(() => []);

            formik.setFieldValue("uploadStatus", "", shouldValidateOnChange);
        }
    }, [uploadData]);

    useEffect(() => {
        if (companiesData?.companies?.length) {
            updateSceneSelectOptions(
                "companyId",
                companiesData?.companies.map(({ companyId, companyName }) => ({
                    value: companyId,
                    label: companyName,
                })),
            );
        }

        updateLoading("companyId", companiesDataLoading);
    }, [companiesData, companiesDataLoading]);

    const updateFrameDescription = (value, index) => {
        setFrames(draft => {
            draft[index].label = value;
            return draft;
        });
        formik.setFieldValue(
            `frame-${index}-description`,
            value,
            shouldValidateOnChange,
        );
    };

    const updateFileTemplatePath = (value, index) => {
        setFrames(draft => {
            draft[index].compositingTemplateFilePath = value;
            return draft;
        });
        formik.setFieldValue(
            `frame-${index}-template-file-path`,
            value,
            shouldValidateOnChange,
        );
    };

    const updateComponentDescription = (value, index) => {
        setComponents(draft => {
            draft[index].description = value;
            return draft;
        });

        formik.setFieldValue(
            `component-${index}-description`,
            value,
            shouldValidateOnChange,
        );
    };

    useEffect(() => {
        const descriptions = components
            .filter(item => item.description)
            .map(item => ({
                count: 1,
                description: item.description,
            }))
            .reduce((a, b) => {
                a[b.description] = (a[b.description] || 0) + b.count;
                return a;
            }, {});

        components.map((component, index) => {
            const key = `component-${index}-description`;
            if (descriptions[component.description] > 1) {
                formik.setFieldError(key, "Description must be unique");
            } else if (component.description) {
                formik.setFieldError(key);
            }
        });
    }, [components]);

    const submit = (): void => {
        setShouldValidateOnChange(true);
        formik.handleSubmit();
    };

    const actionButtons = readOnly => (
        <Fragment>
            <Button
                className="mr-3"
                outline
                onClick={() => navigate("/scenes")}
            >
                {readOnly ? "Back" : "Cancel"}
            </Button>
            {!readOnly && (
                <Button
                    onClick={submit}
                    disabled={
                        !formik.isValid ||
                        uploadLoading ||
                        frameDetailsLoading ||
                        !dirty
                    }
                >
                    {submitLoading || frameDetailsLoading ? (
                        <Spinner size="sm" />
                    ) : (
                        type
                    )}
                </Button>
            )}
        </Fragment>
    );

    return (
        <Container fluid>
            <Heading title={title} rightContent={actionButtons(readOnly)} />
            <ErrorHandling />
            {!readOnly && (
                <Fragment>
                    <Heading title={"Upload scene"} level={5} />
                    <Card>
                        <FileUpload
                            onAddFile={uploadFile}
                            infoContent={
                                (sceneUploadStatus &&
                                    sceneUploadStatus.status === "VALID") ||
                                uploadLoading ||
                                updateSceneUploadStatusLoading
                                    ? ""
                                    : "Please select a valid .zip file for upload"
                            }
                            validExtensions=".zip"
                            progress={uploadProgress}
                            isUploading={uploadLoading}
                            uploadFinished={uploadFinished}
                            onReset={resetUpload}
                            error={errorOnUpload}
                            fileName={fileName}
                        />
                        {formik.errors.fileUrl && (
                            <small className="text-center text-danger mt-2">
                                Upload Required
                            </small>
                        )}
                        {uploadData?.Location &&
                            (!!formik.errors.uploadStatus ||
                                sceneUploadStatus?.status === "INVALID") &&
                            !frameDetailsLoading && (
                                <small className="text-center text-danger mt-2">
                                    {formik.errors.uploadStatus}
                                </small>
                            )}
                    </Card>
                </Fragment>
            )}
            <Heading title={"Scene details"} level={5} />
            <Card>
                {scenesLoading || dataLoading ? (
                    <LoadIndicator cols={2} rows={3} />
                ) : (
                    <DetailsForm
                        settings={sceneSettings}
                        data={formik.values}
                        onChange={data => {
                            formik.setFieldValue(
                                data.key,
                                data.value || "",
                                shouldValidateOnChange,
                            );
                            setDirty(true);
                        }}
                        onAddItem={({ key, value }) => {
                            const options =
                                sceneSettings.find(
                                    setting => setting.key === key,
                                )?.selectOptions || [];
                            updateSceneSelectOptions(key, [
                                ...options,
                                {
                                    value,
                                    label: value,
                                },
                            ]);
                        }}
                        errors={formik.errors}
                    />
                )}
            </Card>
            <div className="mb-3">
                <Heading title={"Frame details"} level={5} />
                {(!frames?.length || frameDetailsLoading || dataLoading) && (
                    <Card>
                        {frameDetailsLoading ? (
                            <LoadIndicator rows={3} cols={2} />
                        ) : (
                            <span className={style.frame_details__label}>
                                Please upload the scene file first in order to
                                select the frame details options.
                            </span>
                        )}
                    </Card>
                )}
                {!!frames?.length && !frameDetailsLoading && (
                    <Datatable
                        disableRowSelect
                        hideNavigation
                        disableSort
                        settings={frameTableSettings}
                    >
                        <FrameDetailsBody
                            frames={frames}
                            readOnly={readOnly}
                            fileTemplates={templateFilePaths}
                            descriptionOptions={frameDescriptions}
                            onChangeDescription={(value, index) => {
                                updateFrameDescription(value, index);
                                setDirty(true);
                            }}
                            onChangeFileTemplatePath={(value, index) => {
                                updateFileTemplatePath(value, index);
                                setDirty(true);
                            }}
                            onAddDescription={value =>
                                setFrameDescriptions(draft => [...draft, value])
                            }
                            errors={formik.errors}
                        />
                    </Datatable>
                )}
            </div>
            <div className="mb-3">
                <Heading title={"Component descriptions"} level={5} />
                {(!components?.length ||
                    frameDetailsLoading ||
                    dataLoading) && (
                    <Card>
                        {frameDetailsLoading ? (
                            <LoadIndicator rows={3} cols={2} />
                        ) : (
                            <span className={style.frame_details__label}>
                                Please upload the scene file first in order to
                                select the component description options.
                            </span>
                        )}
                    </Card>
                )}
                {!!components?.length && !frameDetailsLoading && (
                    <Datatable
                        disableRowSelect
                        hideNavigation
                        disableSort
                        data={components}
                        settings={componentTableSettings}
                    >
                        <ComponentDescriptionsBody
                            type={type}
                            readOnly={readOnly}
                            components={components}
                            onChange={(value, index) => {
                                updateComponentDescription(value, index);
                                setDirty(true);
                            }}
                            descriptions={
                                componentDescriptions?.result[0]?.results.map(
                                    option => ({
                                        value: option,
                                        label: option,
                                    }),
                                ) || []
                            }
                            errors={formik.errors}
                        />
                    </Datatable>
                )}
            </div>
            {!readOnly && (
                <Fragment>
                    <Heading title={"Comment"} level={5} />
                    <Input
                        style={{ height: "10rem" }}
                        value={formik.values["comment"]}
                        type="textarea"
                        placeholder="Type comment here"
                        onChange={({ target: { value } }) => {
                            formik.setFieldValue(
                                "comment",
                                value,
                                shouldValidateOnChange,
                            );
                            setDirty(true);
                        }}
                    />
                    {formik.errors.comment && (
                        <div className="text-danger mt-1">
                            {formik.errors.comment}
                        </div>
                    )}
                </Fragment>
            )}
        </Container>
    );
};

export default EditScene;
