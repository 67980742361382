//@flow

import { useNotifications } from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import { useEffect, useState } from "react";
import { useImmer } from "use-immer";

export const addedField = "addedIds";
export const removedField = "removedIds";

type Props = {
    apiBase: string,
    setsKey: string,
    setKey: string,
    setId: string,
    setItemId?: string,
    setNameKey: string,
    setQueryKey?: string,
};

const useViewSets = ({
    apiBase,
    setsKey,
    setKey,
    setId,
    setItemId,
    setNameKey,
    setQueryKey,
}: Props): * => {
    const { addNotification } = useNotifications();
    const [metadata, setMetaData] = useImmer({});
    const [indexSetToExtend, setIndexSetToExtend] = useState();
    const [indexNewSet, setIndexNewSet] = useState();
    const [itemToRelink, _setItemToRelink] = useState<{
        indexSet: number,
        indexItem: number,
    } | void>();

    const [sets, setSets] = useImmer([]);

    const [customColumns, _setCustomColumns] = useState([]);

    const [{ loading, data }, fetchData] = useAxios(apiBase, {
        useCache: false,
    });

    const [{ loading: deleteSetLoading }, _deleteSet] = useAxios(
        {
            method: "DELETE",
        },
        {
            useCache: false,
            manual: true,
        },
    );

    const [{ loading: createSetLoading }, _createSet] = useAxios(
        {
            method: "POST",
        },
        {
            useCache: false,
            manual: true,
        },
    );

    const [{ data: updateSetData, loading: updateSetLoading }, _updateSet] =
        useAxios(
            {
                method: "PUT",
            },
            {
                useCache: false,
                manual: true,
            },
        );

    const setCustomColumns = (columns: string[]) => {
        _setCustomColumns(
            columns.map(type => ({
                key: type,
                label: type,
                editable: true,
                meta: true,
                width: "5%",
            })),
        );
    };

    useEffect(() => {
        if (data?.[setsKey]?.length) {
            setSets(() =>
                data[setsKey].map(entry => ({
                    ...entry,
                })),
            );
        }

        setCustomColumns(data?.viewMetadataFields || []);
    }, [data]);

    useEffect(() => {
        const newMetaFields = {};
        setMetaData(() => {
            customColumns.forEach(column => (newMetaFields[column.key] = ""));
            return newMetaFields;
        });
    }, [customColumns]);

    const _deleteSetFromState = (index: number): void => {
        setSets(draft => {
            draft.splice(index, 1);
            return draft;
        });
    };

    const addItemToSet = item => {
        setSets(draft => {
            if (setItemId) {
                if (!draft[indexSetToExtend][addedField]) {
                    draft[indexSetToExtend][addedField] = new Set();
                }

                if (!draft[indexSetToExtend][removedField]) {
                    draft[indexSetToExtend][removedField] = new Set();
                }

                const itemId = item[setItemId];

                if (draft[indexSetToExtend][removedField].has(itemId)) {
                    draft[indexSetToExtend][removedField].delete(itemId);
                } else {
                    draft[indexSetToExtend][addedField].add(itemId);
                }
            }

            draft[indexSetToExtend][setKey].push(item);
            return draft;
        });
        setIndexSetToExtend();
    };

    const _deleteItemFromSet = (itemIndex: number, setIndex: number): void => {
        setSets(draft => {
            if (setItemId) {
                if (!draft[setIndex][removedField]) {
                    draft[setIndex][removedField] = new Set();
                }

                if (!draft[setIndex][addedField]) {
                    draft[setIndex][addedField] = new Set();
                }

                const itemId = draft[setIndex][setKey][itemIndex][setItemId];

                if (draft[setIndex][addedField].has(itemId)) {
                    draft[setIndex][addedField].delete(itemId);
                } else {
                    draft[setIndex][removedField].add(itemId);
                }
            }

            draft[setIndex][setKey].splice(itemIndex, 1);
            return draft;
        });
    };

    const updateItemValue = (
        itemIndex: number,
        key: string,
        value: string,
        setIndex: number,
    ): void => {
        setSets(draft => {
            if (Object.keys(metadata).includes(key)) {
                draft[setIndex][setKey][itemIndex].metadata[key] = value;
            } else {
                draft[setIndex][setKey][itemIndex][key] = value;
            }
            return draft;
        });
    };

    const updateViewsVersions = (setIndex, versions) => {
        setSets(draft => {
            draft[setIndex].views = draft[setIndex].views.map(view => {
                const newVersion = versions[view.frameVersionId];

                if (newVersion) {
                    return {
                        ...view,
                        ...newVersion,
                        updated: !newVersion.removed,
                    };
                }

                return view;
            });

            return draft;
        });
    };

    const updateSetName = (value: string, setIndex: number): void => {
        setSets(draft => {
            draft[setIndex][setNameKey] = value;
            return draft;
        });
    };

    const updateSetQuery = (value: string, setIndex: number): void => {
        setQueryKey &&
            setSets(draft => {
                draft[setIndex][setQueryKey] = value;
                return draft;
            });
    };

    const relinkItem = newItem => {
        if (typeof itemToRelink === "object") {
            const { indexSet, indexItem } = itemToRelink;
            setSets(draft => {
                draft[indexSet][setKey][indexItem] = {
                    ...draft[indexSet][setKey][indexItem],
                    ...newItem,
                };
                return draft;
            });
        }
        _setItemToRelink();
    };

    const addSet = items => {
        setIndexNewSet(sets.length);
        setSets(() => [...sets, { [setNameKey]: "", [setKey]: items || [] }]);
    };

    const deleteSet = async (url: string, setIndex: number) => {
        try {
            await _deleteSet({ url });
            _deleteSetFromState(setIndex);
            addNotification({
                type: "success",
                body: "Set successfully deleted",
                autoHide: true,
                timeout: 5000,
            });
        } catch {
            addNotification({
                type: "danger",
                body: "Something went wrong. Please try again later",
                autoHide: true,
                timeout: 5000,
            });
        }
    };

    const cancelEdit = (index: number): void => {
        if (index === indexNewSet) {
            _deleteSetFromState(index);
        } else {
            setSets(draft => {
                draft[index] = {
                    ...data[setsKey][index],
                };
                return draft;
            });
        }
        setIndexNewSet();
    };

    const cancelRelink = (): void => {
        _setItemToRelink();
    };

    const createSet = async (
        url: string,
        setData: { [string]: any },
    ): Promise<boolean> => {
        try {
            const { data } = await _createSet({ url, data: setData });
            setSets(draft => {
                draft[indexNewSet] = {
                    ...draft[indexNewSet],
                    [setId]: data[setId],
                };
                return draft;
            });
            setIndexNewSet();
            addNotification({
                type: "success",
                body: "Set successfully created",
                autoHide: true,
                timeout: 5000,
            });
            fetchData();
            return true;
        } catch {
            addNotification({
                type: "danger",
                body: "Something went wrong. Please try again later",
                autoHide: true,
                timeout: 5000,
            });
            return false;
        }
    };

    const updateSet = async (
        url: string,
        data: { [string]: any },
    ): Promise<boolean> => {
        try {
            await _updateSet({ url, data });
            addNotification({
                type: "success",
                body: "Set successfully updated",
                autoHide: true,
                timeout: 5000,
            });
            fetchData();
            return true;
        } catch {
            addNotification({
                type: "danger",
                body: "Something went wrong. Please try again later",
                autoHide: true,
                timeout: 5000,
            });
            return false;
        }
    };

    const handleAction = (
        action: string,
        indexItem: number,
        indexSet: number,
        callBackFunction: () => void,
    ): void => {
        if (action === "DELETE") {
            _deleteItemFromSet(indexItem, indexSet);
        }
        if (action === "RELINK") {
            _setItemToRelink({
                indexSet,
                indexItem: indexItem,
            });
            callBackFunction();
        }
    };

    useEffect(() => {
        if (
            sets.length &&
            (indexNewSet || indexNewSet === 0) &&
            typeof window !== "undefined"
        ) {
            window.scrollTo(0, document.body?.scrollHeight);
        }
    }, [sets.length, indexNewSet]);

    return [
        {
            sets,
            updateSetData,
            customColumns,
            loading,
            indexNewSet,
            indexSetToExtend,
            itemToRelink,
            deleteSetLoading,
            createSetLoading,
            updateSetLoading,
            metadata,
        },
        cancelEdit,
        setIndexSetToExtend,
        updateItemValue,
        updateSetName,
        updateSetQuery,
        relinkItem,
        addItemToSet,
        addSet,
        deleteSet,
        createSet,
        updateSet,
        handleAction,
        fetchData,
        cancelRelink,
        updateViewsVersions,
    ];
};

export default useViewSets;
